import moment from "moment-timezone";
import {filter, find, findIndex, forEach, groupBy} from "lodash";
import {isWeekend} from "./day";
import {getDayContract} from "./format";

const TYPE_RESERVATION_HARUCAR = 'HARUCAR';
const TYPE_RESERVATION_BEFORE_MONTH = 'BEFORE_MONTH';
const TYPE_RESERVATION_BEFORE_DAY = 'BEFORE_DAY';
const TYPE_RESERVATION_TODAY = 'TODAY';

const TYPE_RESERVATION_PRIORITY = {
    [TYPE_RESERVATION_HARUCAR]: 0,
    [TYPE_RESERVATION_BEFORE_MONTH]: 1,
    [TYPE_RESERVATION_BEFORE_DAY]: 2,
    [TYPE_RESERVATION_TODAY]: 3,
};

export const isShowContractCalendar = (contract) => {
    const end = moment.tz('Asia/Seoul').subtract(3, 'month').subtract(1, 'week').format('YYYY-MM-DD');
    let contracts = filter(contract.vehicle.contracts, c => c.endDate === null || end < c.endDate);
    if (contract.endDate && contract.endDate < end) {
        return false;
    }
    if (contracts.length > 1 || contract?.vehicle?.doorive === false) {
        return true;
    }
    return false;
}

export const isShowContractWallet = (contract) => {
    const end = moment.tz('Asia/Seoul').subtract(1, 'month').format('YYYY-MM-DD');
    let contracts = filter(contract.vehicle.contracts, c => c.endDate === null || end < c.endDate);
    if (!contract.vehicle) {
        return false;
    }
    if (contract.endDate && contract.endDate < end) {
        return false;
    }
    if (contracts.length > 1) {
        return true;
    }
    if (contracts.length === 1 && contracts[0].contractType === 'SOLOCAR') {
        return true;
    }
    return false;
}

export const toReservationDate = (trip) => {
    // 새벽 6시 이전의 주행은 전날 주행으로 간주한다.
    const seoulTime = moment.tz(`${trip.date} ${trip.meta.시작시간}`, 'YYYY-MM-DD HH:mm:ss', 'Asia/Seoul');
    seoulTime.add(-6, 'hours');
    return seoulTime.format('YYYY-MM-DD');
}
const _calcReservationType = (createdAt, usedAt) => {
    if (createdAt >= usedAt) {
        return TYPE_RESERVATION_TODAY;
    } else if (createdAt.year() !== usedAt.year() || createdAt.month() !== usedAt.month()) {
        return TYPE_RESERVATION_BEFORE_MONTH;
    } else {
        return TYPE_RESERVATION_BEFORE_DAY;
    }
}

export const calcReservationType = (reservation) => {
    if (!reservation.contract) {
        return TYPE_RESERVATION_HARUCAR;
    }
    const createdAt = moment.tz(reservation.createdAt, 'Asia/Seoul');
    const usedAt = moment.tz(reservation.useDate, 'Asia/Seoul');

    // 오후 6시부터 확정되고, 당일 예약 처리
    createdAt.add(6, 'hour');

    let reservationType = _calcReservationType(createdAt, usedAt);
    if (reservationType === TYPE_RESERVATION_BEFORE_MONTH) {
        // 확정 전 예약이더라도, 우선권이 없으시다면 전달에 예약한것으로 취급하지 않음
        if (usedAt.date() % 2 === 1) {
            if (reservation.contract.contractType === 'DOORICAR') {
                return TYPE_RESERVATION_BEFORE_DAY;
            }
        } else {
            if (reservation.contract.contractType === 'DOORICAR_WAITING') {
                return TYPE_RESERVATION_BEFORE_DAY;
            }
        }
    }
    return reservationType;
}

export const calcWinner = (reservations) => {
    let minPriority = null;
    let minCreated = null;
    let minIndex = null;
    for (let idx in reservations) {
        idx = Number(idx);
        const res = reservations[idx];
        const priority = res.priority;
        const resCreated = moment.tz(res.createdAt, 'Asia/Seoul');
        if (minPriority === null || minPriority > priority) {
            minPriority = priority;
            minCreated = resCreated;
            minIndex = idx;
        } else if (minPriority === priority && minCreated > resCreated) {
            minPriority = priority;
            minCreated = resCreated;
            minIndex = idx;
        }
    }
    return minIndex;
}

export const calcNeedToken = (useDate, priority) => {
    if (priority === 1) {
        return 0;
    }
    if (isWeekend(useDate)) {
        return 2;
    }
    return 1;
}

export const calcTripKmSum = (trips, selectedMonth, contractId) => {
    const start = getFirstDay(selectedMonth);
    const end = getLastDay(selectedMonth);

    const dates = groupBy(filter(trips, (trip) => {
        let useMoment = moment.tz(trip.useDate, 'Asia/Seoul');
        return start <= useMoment && useMoment <= end;
    }),(trip) => trip.useDate);

    let km = 0;
    forEach(dates, (date) => {
        for (let trip of date) {
            if (trip.contract?.id === contractId) {
                km = km + trip.km;
            } else if (!trip.contract) {
                km = km + (trip.km / 2);
            }
        }
    })
    return Math.round(km * 10) / 10;
}

export const calcTripHipassSum = (trips, selectedMonth, contractId) => {
    const start = getFirstDay(selectedMonth);
    const end = getLastDay(selectedMonth);

    const dates = groupBy(filter(trips, (trip) => {
        let useMoment = moment.tz(trip.useDate, 'Asia/Seoul');
        return start <= useMoment && useMoment <= end;
    }),(trip) => trip.useDate);

    let hipass = 0;
    forEach(dates, (date) => {
        for (let trip of date) {
            if (trip.contract?.id === contractId) {
                hipass = hipass + trip.hipass;
            } else if (!trip.contract) {
                hipass = hipass + (trip.hipass / 2);
            }
        }
    })
    return hipass;
}

export const calcUsedToken = (reservations, selectedMonth, contractId) => {
    const start = getFirstDay(selectedMonth);
    const end = getLastDay(selectedMonth);

    const dates = groupBy(filter(reservations, (reservation) => {
        let useMoment = moment.tz(reservation.useDate, 'Asia/Seoul');
        return start <= useMoment && useMoment <= end;
    }),(reservation) => reservation.useDate);

    let count = 0;
    forEach(dates, (date) => {
        const my = findIndex(date, (reservation) =>
            reservation.contract?.id === contractId);
        if (my !== -1) {
            if (my === calcWinner(date)) {
                count = count + calcNeedToken(date[my].useDate, date[my].priority);
            }
        }
    })
    return count;
}

export const getFirstDay = (date) => {
    let now = date.clone();
    now.date(1);
    now.hour(0);
    now.minute(0);
    now.second(0);
    now.millisecond(0);
    return now;
}

export const getLastDay = (date) => {
    let now = date.clone();
    now.add(1, 'month');
    now.date(1);
    now.hour(0);
    now.minute(0);
    now.second(0);
    now.millisecond(0);
    now.subtract(1, 'day');
    return now;
}


export const canRequest = (contractId, contract, useDate, dayReservation, dayRequest) => {
    const vehicle = contract.vehicle;

    if (dayRequest.length) {
        return {
            result: 'ERROR',
            msg: '이미 요청이 존재하는 날입니다',
        }
    }

    let dayContracts = getDayContract(vehicle.contracts, useDate);
    let hostContract = find(dayContracts, (c) => c.contractType === 'DOORICAR_WAITING');
    const myReservation = find(dayReservation, r => r.contract.id === contract.id);
    const otherReservation = find(dayReservation, r => r.contract.id !== contract.id);

    if (myReservation) {
        return {
            result: 'ERROR',
            msg: '이미 예약이 되어있는 날입니다',
        };
    }

    if (hostContract.useType === 'PRIORITY_GUEST') {
        if (contract.contractType === 'DOORICAR_WAITING') {
            return {
                result: 'OK',
                autoAccept: !otherReservation,
            }
        }
    } else if (hostContract.useType === 'PRIORITY_HOST') {
        if (contract.contractType !== 'DOORICAR_WAITING') {
            return {
                result: 'OK',
                autoAccept: !otherReservation,
            }
        }
    }

    if (otherReservation) {
        return {
            result: 'OK',
            autoAccept: !otherReservation,
        }
    }

    return {
        result: 'RESERVATION',
        msg: '상대의 예약이 없는 날입니다',
    };
}
